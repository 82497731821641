import './App.css';
import SKL_DIGITAL_Clear from './img/logo/SKL_DIGITAL_Clear.png'
import SKL_DIGITAL  from './img/logo/SKL_DIGITAL.jpg'
import {useLayoutEffect, useState} from "react";
import USER from "./img/icon/user.svg"
import CUP from "./img/icon/cup.svg"
import LIKE from "./img/icon/like.svg"
import JINKIN from "./img/photo/jinkin.jpg"
import CHERNUHIN from "./img/photo/chernukhin_new.jpg"
import YAROSHENKO from "./img/photo/yaroshenko_new.jpg"
import FROLOV from "./img/photo/frolov_new.jpg"
import VOLOSOV from "./img/photo/volotskov_new.jpg"
import TCIGANKOV from "./img/photo/tsigankov.jpg"
import FEDOSOV from "./img/photo/fedosov.jpg"
import GAIVORONSKAYA from "./img/photo/gaivoronskaya_new.jpg"
import ONE_C from "./img/logo/1c.jpg"
import FORECAST from "./img/logo/forecat.png"
import SANTECH from "./img/logo/santech.jpg"
import SKL from "./img/logo/SKL_group_Logo.jpg"
import POLYPLAST from "./img/logo/polylast.jpg"
import APPSAN from "./img/logo/appsan.jpg"
import NLC from "./img/logo/nlc.jpg"

import WHY from './img/photo/why.jpg'
import DIGITAL1 from "./img/photo/digital1.jpg"
import DIGITAL2 from "./img/photo/digital2.jpeg"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function App() {

    const [open, setOpen] = useState(false)

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    useLayoutEffect(() => {
        window.addEventListener('scroll', () => {
            let header = document.querySelector('.header')
            let arrow = document.querySelector('.scroll_top')
            window.pageYOffset === 0 ? header.classList.remove('white') : header.classList.add('white')
            window.pageYOffset > 600 ? arrow.classList.add('visible') : arrow.classList.remove('visible')
        })
    })

    function scrollToElem() {
        const element = document.getElementById('start_block');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

  return (
      <>
          <a onClick={scrollToElem} className="scroll_top"></a>
          <div className="conteiner">
              <div className="conteiner__wrap">
                  <header className="header">
                      <div className="header__wrap">
                          <div className="header__logo">
                              <a href="#start_block">
                              <img src={SKL_DIGITAL_Clear} className="header__img" alt="SKL_DIGITAL_LOGO"/>
                              </a>
                          </div>
                          <nav className="menu">
                              <div className="menu__wrap">
                                  <ul className="menu__list">
                                      <li className="menu__item">
                                          <a href="#about_block" className="menu__link">О компании</a>
                                      </li>
                                      <li className="menu__item">
                                          <a href="#why_block" className="menu__link">Компетенции</a>
                                      </li>
                                      <li className="menu__item">
                                          <a href="#team" className="menu__link">Команда</a>
                                      </li>
                                      <li className="menu__item">
                                          <a href="#projects" className="menu__link">Проекты</a>
                                      </li>
                                      <li className="menu__item">
                                          <a href="#clients" className="menu__link">Клиенты</a>
                                      </li>
                                      <li className="menu__item">
                                          <a href="#contacts" className="menu__link">Контакты</a>
                                      </li>
                                  </ul>
                              </div>
                          </nav>
                      </div>
                  </header>
                  <main className="main" id="start_block">
                      <div className="main__wrap">
                          <div className="main_logo">
                              <h1><img src={SKL_DIGITAL_Clear} alt="SKL DIGITAL" className="main__img"/></h1>
                          </div>
                      </div>
                  </main>
                  <section className="about" id="about_block">
                      <div className="about__wrap">
                          <div className="about__inner">
                              <h2 className="about__title">О КОМПАНИИ</h2>
                              <div className="about__text">
                                  <strong>SKL DIGITAL - системный интегратор и разработчик IT - систем для бизнеса</strong>, предоставляющий услуги разработки, развития, внедрения и эксплуатации IT – решений.
                                  <br />
                                  <br />
                                  <strong>SKL DIGITAL решает IT - задачи</strong> в сфере цифрового развития бизнеса для повышения операционной эффективности.
                                  <br />
                                  <br />
                                  <strong>SKL DIGITAL оказывает IT - услуги</strong> в области:
                                  <ul className="about__list">
                                      <li>внедрения и развития комплексных систем автоматизации управления предприятием,</li>
                                      <li>внедрения проектов по автоматизации бизнес-операций и бизнес-процессов,</li>
                                      <li>создания и внедрения систем управления данными,</li>
                                      <li>кастомизации систем автоматизации управления предприятием,</li>
                                      <li>тестирования информационных систем,</li>
                                      <li>сопровождения программного обеспечения реализованных проектов,</li>
                                      <li>создания центров обработки и систем хранения данных,</li>
                                      <li>разработки веб сайтов.</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="why" id="why_block">
                      <div className="why__wrap">
                          <div className="why__inner">
                              <h2 className="why__title">Почему мы?</h2>
                              <div className="why__content">
                                  <div className="why__left">
                                      <div className="why__left__item">
                                          <div className="why__left__icon">
                                              <img src={USER} className="why__left__svg" alt="user_icon"/>
                                          </div>
                                          <div className="why__left__text">
                                              <strong>SKL DIGITAL</strong> - инновационная IT-сервисная компания на российском рынке с большой командой профессионалов: архитекторов, аналитиков, бизнес-консультантов, методологов, инженеров, программистов и тестировщиков.
                                          </div>
                                      </div>
                                      <div className="why__left__item">
                                          <div className="why__left__icon">
                                              <img src={CUP} className="why__left__svg" alt="user_cup"/>
                                          </div>
                                          <div className="why__left__text">
                                              Ключевые технологии:
                                              <ul className="why__left__list">
                                                  <li>Языки разработки: C#, PHP, .NET, ASP.NET, JavaScript, 1С.</li>
                                                  <li>Базы данных: MS SQL Server, PostgreSQL, MySQL.</li>
                                                  <li>Оркестрация: VMWare.</li>
                                                  <li>Мониторинг: Zabbix.</li>
                                                  <li>Контроль версий: Git, TFS.</li>
                                                  <li>Непрерывная интеграция: GitLab.</li>
                                              </ul>
                                          </div>
                                      </div>
                                      <div className="why__left__item">
                                          <div className="why__left__icon">
                                              <img src={LIKE} className="why__left__svg" alt="user_icon"/>
                                          </div>
                                          <div className="why__left__text">
                                              <strong>SKL DIGITAL</strong> строит долгосрочное партнерство со своими клиентами, основываясь на глубокой экспертизе, инновациях и высоком качестве работ.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="why__right">
                                      <div className="why__right__inner">
                                          <img src={DIGITAL1} className="why__right__img" alt="WHY"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="team" id="team">
                      <div className="team__wrap">
                          <h2 className="team__title">Компетенции нашей команды</h2>
                          <p className="team__description">Компетенции нашей компании выражены в компетенциях команды наших профессионалов</p>

                          <div className="image-slider-container">
                              <Slider {...settings}>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={JINKIN} className="team__item__img" alt="Jinkin_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Жинкин Алексей</h4>
                                              <div className="team__item__competencies">CEO, MBA</div>
                                              <div className="team__item__competencies">Цифровая трансформация бизнеса</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={CHERNUHIN} className="team__item__img" alt="CHERNUHIN_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Чернухин Михаил</h4>
                                              <div className="team__item__competencies">Руководитель ERP-систем</div>
                                              <div className="team__item__competencies">Эксперт по внедрению 1С: ERP</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={YAROSHENKO} className="team__item__img" alt="YAROSHENKO_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Ярошенко Антон</h4>
                                              <div className="team__item__competencies">Руководитель направления развития и эксплуатации корпоративного программного обеспечения</div>
                                              <div className="team__item__competencies">Эксперт по внедрению и интеграции систем управления жизненным циклом изделий</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={FROLOV} className="team__item__img" alt="FROLOV_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Фролов Алексей</h4>
                                              <div className="team__item__competencies">Руководитель технической службы</div>
                                              <div className="team__item__competencies">Эксперт по инфраструктурному развитию</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={VOLOSOV} className="team__item__img" alt="VOLOSOV_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Волоцков Дмитрий</h4>
                                              <div className="team__item__competencies">PMO, MBA</div>
                                              <div className="team__item__competencies">Руководитель проектного офиса</div>
                                              <div className="team__item__competencies">Проектное управление</div>
                                              <div className="team__item__competencies">Маркетинговое управление</div>
                                              <div className="team__item__competencies">Управление развитием</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={TCIGANKOV} className="team__item__img" alt="TCIGANKOV_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Цыганков Владимир</h4>
                                              <div className="team__item__competencies">Руководитель проектов, Архитектор Dynamics CRM</div>
                                              <div className="team__item__competencies">Эксперт по внедрению, интеграции и развитию MS Dynamics CRM систем</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div className="team__item">
                                          <div className="team__item__photo">
                                              <img src={GAIVORONSKAYA} className="team__item__img" alt="FEDOSOV_photo"/>
                                          </div>
                                          <div className="team__item__text">
                                              <h4 className="team__item__name">Гайворонская Наталья</h4>
                                              <div className="team__item__competencies">ИТ-аккаунт менеджер</div>
                                              <div className="team__item__competencies">Специалист по взаимодействию с клиентами</div>
                                          </div>
                                      </div>
                                  </div>
                              </Slider>
                          </div>
                      </div>
                  </section>
                  <section className="projects" id="projects">
                      <div className="projects__wrap">
                          <div className="projects__inner">
                              <div className="project__item">
                                  <div className="project__item__top">
                                      <h2 className="project__item__title">Внедрение 1C ERP в группе компаний SKL group</h2>
                                      <div className="project__item__logo">
                                          <img src={ONE_C} className="project__item__img" alt="one_c"/>
                                      </div>
                                  </div>
                                  <div className="project__item__text">
                                      <p>В 2021 году в компании ООО «СКЛ» был реализован проект по переходу с 1С Предприятие 8.3. на 1C ERP 2.0.</p>
                                      <p>Причиной инициации этого проекта послужила потребность перехода на более технологичную платформу, повышения производительности, внедрения новейшего функционала программы.</p>
                                      <p>Сотрудниками «СКЛ Цифровой» и «СКЛ» была проделана работа по внедрению и настройке 1C ERP Управление предприятием 2.0, интеграции и синхронизации баз данных.</p>
                                      <p>В результате удалось:</p>
                                      <ul className="accomplishment">
                                          <li>увеличить скорость и качество работы системы, что позволило сократить время, требуемое операторам для обработки данных в 1С;</li>
                                          <li>повысить скорость доступа к информации, необходимой для принятия управленческих решений;</li>
                                          <li>автоматизировать неохваченные бизнес-процессы;</li>
                                          <li>увеличить скорость обслуживания клиентов.</li>
                                      </ul>
                                  </div>
                              </div>
                              <div className="project__item">
                                  <div className="project__item__top">
                                      <h2 className="project__item__title">Автоматизация системы управления товарными запасами в группе компаний SKL group</h2>
                                      <div className="project__item__logo">
                                          <img src={FORECAST} className="project__item__img" alt="FORECAST"/>
                                      </div>
                                  </div>
                                  <div className="project__item__text">
                                      <p>В 2022 году в ООО «СКЛ» был реализован проект по внедрению программного обеспечения FC NOW!</p>
                                      <p>Причина инициации этого проекта - потребность отдела закупок в едином инструменте для управления товарными запасами компании.</p>
                                      <p>Сотрудниками «СКЛ Цифровой» и «СКЛ» была проделана работа по интеграции и синхронизации баз данных:</p>

                                      <ul className="accomplishment">
                                          <li>установлено программное обеспечение для выполнения автоматизации управления товарными запасами;</li>
                                          <li>внесены данные и настроены первичные параметры, необходимые для промышленного запуска программы;</li>
                                          <li>настроена ежедневная автоматическая загрузка данных из ERP.</li>
                                      </ul>


                                      <p>В результате удалось:</p>
                                      <ul className="accomplishment">
                                          <li>сократить скорость рутинных процессов УТЗ в 3 раза;</li>
                                          <li>сократить время на расчет новых заказов в 24 раза;</li>
                                          <li>сократить пополнение запасов по складам в 16 раз.</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                          <div className="projects__bottom">
                              <h2 className="projects__bottom__title">Этапы работ при внедрении проектов</h2>
                              <div className="projects__bottom__list">
                                  <div className="projects__bottom__item">
                                      <div className="projects__bottom__number">1</div>
                                      <div className="projects__bottom__text">
                                          <div className="projects__bottom__topic">С чего начнем?</div>
                                          <div className="projects__bottom__description">Анализ потребностей и формирование брифа</div>
                                          <div className="projects__bottom__description">Анализ и корректировка бизнес процесса</div>
                                      </div>
                                  </div>
                                  <div className="projects__bottom__item">
                                      <div className="projects__bottom__number">2</div>
                                      <div className="projects__bottom__text">
                                          <div className="projects__bottom__topic">Рабочий процесс</div>
                                          <div className="projects__bottom__description">Определение IT-архитектуры</div>
                                          <div className="projects__bottom__description">Разработка IT-системы</div>
                                          <div className="projects__bottom__description">Настройка</div>
                                          <div className="projects__bottom__description">Тестирование</div>
                                      </div>
                                  </div>
                                  <div className="projects__bottom__item">
                                      <div className="projects__bottom__number">3</div>
                                      <div className="projects__bottom__text">
                                          <div className="projects__bottom__topic">Какой результат?</div>
                                          <div className="projects__bottom__description">Работающая система информационной автоматизации.</div>
                                          <div className="projects__bottom__description">Повышение эффективности бизнеса.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="clients" id="clients">
                      <div className="clients__wrap">
                          <div className="clients__inner">
                              <h2 className="clients__title">Наши клиенты</h2>
                              <p className="clients__description">Клиенты нашей компании из сферы производственного и дистрибьюционного бизнеса</p>
                              <div className="clients__list">
                                  <div className="clients__item">
                                      <img src={SANTECH} className="clients__img" alt="SANTECH"/>
                                  </div>
                                  <div className="clients__item">
                                      <img src={SKL} className="clients__img" alt="SKL"/>
                                  </div>
                                  <div className="clients__item">
                                      <img src={POLYPLAST} className="clients__img" alt="POLYPLAST"/>
                                  </div>
                                  <div className="clients__item">
                                      <img src={APPSAN} className="clients__img" alt="APPSAN"/>
                                  </div>
                                  <div className="clients__item">
                                      <img src={NLC} className="clients__img" alt="NLC"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="contacts" id="contacts">
                      <div className="contacts__wrap">
                          <div className="contacts__inner">
                              <h2 className="contacts__title">Контакты</h2>
                              <div className="contacts__list">
                                  <a href="http://skl-digital.ru" className="contacts__link">http://skl-digital.ru</a><br/>
                                  <a href="tel:+79119403045" className="contacts__link">Телефон: +7 911 940 30 45</a><br/>
                                  <a href="mailto:skl@skl-digital.ru" className="contacts__link">Почта: skl@skl-digital.ru</a>
                              </div>
                              <div className="contacts__address">
                                  196140, Россия, Санкт-Петербург,
                                  Пулковское шоссе, д. 56, к. 4, лит. А
                              </div>
                              <div className="contacts__policy" onClick={() => setOpen(true)}>Реквизиты</div>
                              <a href="/files/policy.pdf" className="contacts__policy" target="_blank">Политика конфиденциальности</a>
                          </div>
                      </div>
                  </section>
                  <div className={ 'modal_requisites ' + (open ? 'open' : '')}>
                      <div className="modal_requisites__bg" onClick={() => setOpen(false)}>
                      </div>
                      <div className="modal_requisites__wrap">
                          <div className="modal_requisites__close" onClick={() => setOpen(false)}></div>
                          <div className="modal_requisites__inner">
                              <h4>ООО СКЛ ЦИФРОВОЙ</h4>
                              <p className="requisites__text">ИНН	          7820081345</p>
                              <p className="requisites__text">ОГРН          1227800072070</p>
                              <p className="requisites__text">Юридический адрес: 196140, Г.САНКТ-ПЕТЕРБУРГ, ВН.ТЕР.Г. ПОСЕЛОК ШУШАРЫ, Ш ПУЛКОВСКОЕ, Д. 56, К. 4, ЛИТЕРА А, ПОМЕЩ. 1-Н, ПОМЕЩ. 124-127</p>
                          </div>
                      </div>
                  </div>
                  <iframe
                      src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab5e88c4024566106012191f1c236b28e29a365573aad719a21bf4352356d6b21&amp;source=constructor"
                      width="100%" height="450" frameBorder="0"></iframe>
              </div>
          </div>
      </>

  );
}

export default App;
